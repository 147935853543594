@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Pacifico&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Shadows+Into+Light+Two&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barrio&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Barriecito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');

/* .image_circle{
  border: solid 1px #DCE9FF;
  border-radius: 50%;
width:110px;
height:110px;
object-fit:cover;
object-position:50% 50%;
} */

.App {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo_main {
  padding-right: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header1_jett {
  float: left !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ===========Jett CSS =======*/
.slider_home_page_jett .row {
  margin: 0 !important;
}

a {
  text-decoration: none !important;

}

a:hover {
  color: #864AFF !important;
}

.vip_access_btn {

  color: #000;
  font-family: "Oswald", Sans-serif;
  font-weight: 700 !important;
  text-align: center;
  font-size: 18px;
  padding-top: 17px;
}

.btn_login_jett {
  background-color: #864AFF;
  margin: 10px;
  padding: 5px;
  color: #fff;
  font-family: "Oswald", Sans-serif;
  font-weight: 700 !important;
  text-align: center;
  border: solid 1px #864AFF;
  font-size: 18px;
}

.btn_login_jett:hover {

  color: #FFFFFF !important;
  background-color: #000;
}

.video_serie {
  background-color: #000;
  border: solid 3px #864AFF;
  margin: 10px;
  padding: 5px;
  color: #864AFF;
  font-family: "Oswald", Sans-serif;
  font-weight: 700 !important;
  text-align: center;
  font-size: 18px;
}

.video_serie:hover {

  color: #FFFFFF !important;
  background-color: #000;
}

.bnj98 {
  margin-left: -12px;
}

.btn_donate_jett {
  background-color: #FF4E09;
  margin: 10px;
  padding: 5px;
  color: #fff;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  text-align: center;
  border: solid 1px #FF4E09;
  font-size: 18px;
}

.btn_donate_jett:hover {
  color: #FF4E09 !important;
  background-color: transparent;
}

.social_icon {
  color: #FF4E09;
  margin-right: 10px;
}

.avatar_jett {
  border: solid 1px #DCE9FF;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  object-fit: cover;
  object-position: 50% 50%;
}

.jett_dropdown_bg {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #fff;
  padding: 4px;

}

.text_header1 {
  font-size: 14px;
  font-weight: 400;
  color: #FF4E09;
  margin-right: 20px;
}

.text_header11 {
  font-size: 16px;
  font-weight: 400;
  color: #FF4E09;
  margin-right: 20px;
}

.text_header1:hover,
.text_header11:hover {
  color: #864AFF !important;
}

.logo_jett {
  width: 180px;
}

.menu_text {
  font-size: 18px !important;
  font-family: "Oswald", Sans-serif;
  color: #575757 !important;
  font-weight: 700 !important;
}

/* =====Slider home page */
.slider_home_page_jett {
  background-image: url("../../public/images/img/header1.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.left_content {
  float: right;
}

.image_slider {
  width: 350px;
  padding: 20px;
  margin: 0px;
}

.text_slider {
  padding: 20px;
  margin: 0px;
}

.text_slider1 {
  color: #FF4E09;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;

}

.text_slider2 {
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  line-height: 65px;
  border-left: solid 5px #fff;
  padding-left: 15px;
}

.text_slider3 {
  color: #fff;
  font-size: 20px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  line-height: 25px;
}

.text_slider5 {

  color: #FFFFFF;

  font-weight: bold;
  font-family: "Oswald", Sans-serif;
  font-size: 24px;
  opacity: .7;
  line-height: 35px;


}


.btn_slider_jett {
  background-color: #FF4E09;

  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #fff;
  font-weight: 700;
  width: 150px;
  text-align: center;
  border: solid transparent;
  display: block;
}

.btn_slider_jett:hover {
  border: solid 1px #FF4E09;
  color: #FF4E09 !important;
  background-color: transparent;
}

/* ==========Part 2 */
/* .part2{

} */
.text1_part2 {
  color: #FF4E09;
  font-size: 18px;
  font-weight: 700;
}

.text2_part2 {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
}

.bar1_jett {
  height: 4px;
  background-color: #FF4E09;
  width: 150px;
}

.text3_part2 {
  font-family: "Oswald", Sans-serif;
}

.image_2 {
  width: 100%;
}

.top_part2_right {
  justify-content: center;
  text-align: center;
}

.jett_content_part2_23 {
  margin: auto !important;
}

.input_part2_jett {
  width: 100%;
  margin-top: 10px;
  border: solid 1px #FF4E09;
  font-size: 14px;
  font-family: "Oswald", Sans-serif;
  padding: 5px 15px;
}

.input_part2_jett2 {
  width: 100%;
  margin-top: 10px;
  border: solid 1px #a3a09e;
  font-size: 14px;
  font-family: "Oswald", Sans-serif;
  padding: 5px 15px;
}

.textarea_part2_jett {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  border: solid 1px #FF4E09;
  font-size: 14px;
  font-family: "Oswald", Sans-serif;
  padding: 5px 15px;
}

.btn_part2_jett {
  width: 100%;
  background-color: #FF4E09;
  color: #fff;
  border: solid 1px #FF4E09;
  font-weight: 700;
}

.btn_part2_jett:hover {

  background-color: transparent;
  color: #FF4E09;
}


@media only screen and (min-width: 800px) {
  .part2_right {
    margin-top: -100px;
  }
}

/* ==========Part 2-1 */
.jett_98_00 {
  border-bottom: solid 7px #000;

}

.header_jett_89 {
  background-color: #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.text_header_jett_89 {
  color: #F9F9FFD1;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  font-size: 40px;


}

.text2_header_jett_89 {
  color: #000;
  font-family: 'Barrio', system-ui;
  font-size: 55px;
  font-weight: 800;
  text-align: center;

}

.jett_89_Cont74 {
  /* background-image: url("../../public/images/img/persist_img3.png");
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px; */
  background-color: #EAE7FF;
}

.img_part2_1 {
  width: 100%;
}


.part2_1_text1 {
  font-size: 30px;
  line-height: 30px;
}

.part2_1_text2 {
  font-size: 20px;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
}

.cont_jett_yur ul {
  padding: 0;
  margin: 0px;
  list-style-type: none;
}

.cont_jett_yur li {
  background: url('../../public/images/icons/check.png') no-repeat;
  padding-left: 34px;
  height: 34px;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;
  margin-top: -20px;
}

.jett_li875 {
  margin-top: 50px;
}

/* ===========part 3 */

.text1_part3 {
  font-family: 'Oswald', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Permanent Marker', cursive;
  font-size: 35px;
  text-align: center;
}

.text2_part3 {
  font-size: 18px;
  text-align: center;
  margin-top: -10px;

}

.header_part3 {
  background-color: #000;
  color: #FFFFFF;
  padding: 20px;
  font-size: 20px;
}

.text_marque_jett001 {
  opacity: .7;
}

.text_box_video_part3 {
  font-family: "Oswald", Sans-serif;
  opacity: .5;
  font-size: 16px;
}

.iframe_part3 {
  width: 100%;
  height: 190px;

}

.iframe_part4 {
  width: 100%;
  height: 305px;

}

/* ==========Part 4 */

.part4_content {
  background-color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.content_title_part4 {
  margin-top: -50px;
}

.text1_part4 {
  background-color: #FF4E09;
  color: #FFFFFF;
  margin: auto;
  text-align: center;
  font-size: 44px;
  font-weight: 700;


}

.text1_1_part4 {
  font-family: 'Oswald', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Permanent Marker', cursive;
  font-size: 35px;
  color: #FF4E09;
}

.text2_part4 {
  text-align: center;
  margin: auto;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  opacity: .5;
  font-family: "Oswald", Sans-serif;
}

.btn_part4_jett98 {
  background-color: #FF4E09;
  font-size: 18px;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 25px;
  border: solid 1px #FF4E09;
  font-weight: 700;
}

.btn_part4_jett98:hover {
  background-color: transparent;
  color: #FFFFFF !important;
}

.book1_jett {
  width: 70%;
}

.image_part4 {
  width: 60%;
}

/* .content_image_part4{

} */

/* =======part5 */
.part5_content {
  background-color: #DBE9FF;

}

.part5_content2 {
  margin: 20px;
  padding: 20px;
}

.part5_video_box {
  border: solid 10px #864AFF;
}

.donate_text78 {
  color: #864AFF;
}

.donate_text78:hover {
  color: #FF4E09 !important;
}

/* ==============part6 */
.part6 {
  background-image: url("../../public/images/img/final_image_for_bottom_homepage.png");
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}

.part6_header {
  background-color: #FF4E09;
  color: #fff;
  text-align: center;
  margin: auto;
  position: relative;
  z-index: 10;



}

.part6_header2 {
  margin-bottom: -90px;
}

.text1_part6 {
  font-size: 40px;
  font-weight: 700;
}

.text2_part6 {
  font-family: "Oswald", Sans-serif;
  text-align: center;
  margin: auto;
}

.text3_part6 {
  font-size: 30px;
}

.text4_part6 {
  font-family: "Oswald", Sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

.text5_part6 {
  font-weight: 700;
  color: #fff;
  font-size: 18px;
}

.text5_part6_1 {
  font-weight: 700;
  opacity: .5;
  font-size: 18px;
}

.text6_part6 {
  font-size: 34px;
  color: #fff;
  font-weight: 700;

}

/* ==========Register======= */

.background-radial-gradient {
  /* background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%); */

  background-image: url("../../public/images/bg_register.png");
  background-color: #cccccc;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

/* #radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
} */

/* #radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
} */

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
}

.text1_register {
  z-index: 10;
}

.text2_register {
  color: #000;
}

.text3_register {
  color: hsl(218, 81%, 95%)
}

.text4_register {
  color: #000;
}

.form-label {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 10px;
  opacity: .7;
}

/* =========dropdown========= */
.dropdown_jett {


  font-size: 18px;
}


/* =========Login========= */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  font-family: "Oswald", Sans-serif;
}

.footer_jett_flot54 {
  float: right;
}

.forget_test {
  color: #000;
  font-family: "Oswald", Sans-serif;
}

.account_create_text {
  color: #FF4E09;
}


/* TABS */




/* FORM TYPOGRAPHY*/

.form_login input[type=button],
input[type=submit],
input[type=reset] {
  background-color: #864AFF;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_login input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover {
  background-color: #FF4E09;
}

input[type=button]:active,
input[type=submit]:active,
input[type=reset]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.form_login input[type=text],
.form_login input[type=email],
.form_login input[type=password] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus {
  background-color: #fff;
  border-bottom: 2px solid #FF4E09;
}

input[type=text]:placeholder,
input[type=email]:placeholder,
input[type=password]:placeholder {
  color: #cccccc;
}



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #864AFF;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #864AFF;
}

.underlineHover:hover:after {
  width: 100%;
}



/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}

.image_login {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: 50% 50%;
}

.log_jett_text1 {
  font-family: 'Times New Roman', Times, serif;
  color: #000;
}

.log_jett_text1:hover {
  color: #000 !important;
}

.log_jett_text2 {
  color: #FF4E09;
  text-align: center;
}

.log_jett_text2:hover {
  color: #ad1fff;
}

.create_text1_jett {
  color: #ad1fff;
}

.create_text2_jett {
  font-size: 20px;
  color: #000;
}



.text_user1 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 7px;
}

.text_user2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
  opacity: .7;
  line-height: 7px;
}


/* =========================DASHBOARD================== */


.dash_body {
  background: #edf1f5;
}

.card {
  margin-bottom: 30px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.57rem;
}

.note-has-grid .nav-link {
  padding: .4rem
}

.nav-link {
  font-family: "Oswald", Sans-serif;
  font-weight: 700 !important;
}

.note-has-grid .single-note-item .card {
  border-radius: 10px
}

.note-has-grid .single-note-item .favourite-note {
  cursor: pointer
}

.note-has-grid .single-note-item .side-stick {
  position: absolute;
  width: 3px;
  height: 35px;
  left: 0;
  background-color: rgba(82, 95, 127, .5)
}

.note-has-grid .single-note-item .category-dropdown.dropdown-toggle:after {
  display: none
}

.note-has-grid .single-note-item .category [class*=category-] {
  height: 15px;
  width: 15px;
  display: none
}

.note-has-grid .single-note-item .category [class*=category-]::after {
  content: "\f0d7";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 12px;
  color: #fff;
  position: absolute
}

.note-has-grid .single-note-item .category .category-business {
  background-color: rgba(44, 208, 126, .5);
  border: 2px solid #2cd07e
}

.note-has-grid .single-note-item .category .category-social {
  background-color: #FF4E09;
  border: 2px solid #2cabe3
}

.note-has-grid .single-note-item .category .category-important {
  background-color: rgba(255, 80, 80, .5);
  border: 2px solid #FF4E09
}

.note-has-grid .single-note-item.all-category .point {
  color: rgba(82, 95, 127, .5)
}

.note-has-grid .single-note-item.note-business .point {
  color: rgba(44, 208, 126, .5)
}

.note-has-grid .single-note-item.note-business .side-stick {
  background-color: rgba(44, 208, 126, .5)
}

.note-has-grid .single-note-item.note-business .category .category-business {
  display: inline-block
}

.note-has-grid .single-note-item.note-favourite .favourite-note {
  color: #ffc107
}

.note-has-grid .single-note-item.note-social .point {
  color: rgba(44, 171, 227, .5)
}

.note-has-grid .single-note-item.note-social .side-stick {
  background-color: rgba(44, 171, 227, .5)
}

.note-has-grid .single-note-item.note-social .category .category-social {
  display: inline-block
}

.note-has-grid .single-note-item.note-important .point {
  color: rgba(255, 80, 80, .5)
}

.note-has-grid .single-note-item.note-important .side-stick {
  background-color: rgba(255, 80, 80, .5)
}

.note-has-grid .single-note-item.note-important .category .category-important {
  display: inline-block
}

.note-has-grid .single-note-item.all-category .more-options,
.note-has-grid .single-note-item.all-category.note-favourite .more-options {
  display: block
}

.note-has-grid .single-note-item.all-category.note-business .more-options,
.note-has-grid .single-note-item.all-category.note-favourite.note-business .more-options,
.note-has-grid .single-note-item.all-category.note-favourite.note-important .more-options,
.note-has-grid .single-note-item.all-category.note-favourite.note-social .more-options,
.note-has-grid .single-note-item.all-category.note-important .more-options,
.note-has-grid .single-note-item.all-category.note-social .more-options {
  display: none
}

@media (max-width:767.98px) {
  .note-has-grid .single-note-item {
    max-width: 100%
  }
}

@media (max-width:991.98px) {
  .note-has-grid .single-note-item {
    max-width: 216px
  }
}

.jett_menu_dash {
  color: #000 !important;
}

.active {
  background-color: #FF4E09 !important;
  color: #fff !important;
}

.add_button {
  width: 93% !important;
}

.btn_add {
  float: right;
  display: block;
  background-color: #FF4E09;
  color: #fff;
  margin: 10px;
  padding: 10px;
  border-radius: 25px;
  border: solid 1px #FF4E09;
  font-weight: 700;
}

.btn_add:hover {
  color: #FF4E09;
  background-color: transparent;
}

/*============Product List ============ */


.ratings i {
  font-size: 16px;
  color: red
}

.strike-text {
  color: red;
  text-decoration: line-through
}

.product-image {
  width: 100px;
}

.dot {
  height: 7px;
  width: 7px;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 3px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block
}

.spec-1 {
  color: #938787;
  font-size: 15px
}



.para {
  font-size: 16px
}


/* ===========About=============== */

.about_slider_page_jett {
  background-image: url("../../public/images/bg_about.png");
  background-color: #cccccc;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.about_slider_page_jett22 {
  background-color: #000;
}

.img_about9857 {
  /* width: 800px; */
  width: 100%;
}


.text_about_slider {
  color: #fff;
  font-weight: bold;
  font-size: 42px;
  line-height: 45px;
  text-align: center;
}

.text2_about_slider {
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  line-height: 27px;
}




.about_part2 {
  background-color: #000;
}

.header_video_about {
  background-color: #FF4E09;
  padding: 10px;
}

.about_part2_text1 {
  font-family: 'Oswald', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Permanent Marker', cursive;
  font-size: 25px;
  color: #fff;
}

.email_contact009 {
  font-weight: 700;
}

.iframe_part2_about {
  width: 100%;
  height: 500px;
}

.pict_part2_about {
  width: 100%;
}

.content_video_about {
  border: solid 3px #ad1fff;
}

.part2_text_about_78588 {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;

}

.error_text {
  color: brown;
  text-align: center;
}
.error_text2 {
  color: brown;
  text-align: center;
  font-size: 15px;
}

.part2_text_about_78599 {
  color: #000;
  font-weight: bold;
  font-size: 27px;
  line-height: 45px;

}

.about_logo_elite {
  margin-top: -60px;
}

.logo_elite {
  width: 200px;
}

.part2_text2_about_78588 {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  line-height: 25px;

}

.btn_pranze_elite {
  display: block;
  background-color: #000;
  width: 290px;
  padding: 10px;
  font-size: 24px;
  color: #FFFFFF;
  border: solid 1px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 700;
}

.btn_pranze_elite:hover {
  background-color: transparent;
  color: #000;
}

.part3_about {
  background-color: #E1E0FE;
}

.part3_about_content {
  margin: auto;
}

.text_about_10 {
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 22px;

}

.text_about_11 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;
  font-weight: 600;
}

.text_about_12 {
  color: #000;
  font-weight: bold;
  font-size: 26px;
  line-height: 45px;
}



.cont_social_about {
  background-color: #000;
  padding: 10px;
}

.social_about {
  margin: auto;
}

.text1_social_about {
  text-align: center;
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 700;
}

.text2_social_about {
  text-align: center;
  color: #FFFFFF;
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
}

.cont_social_about_part3 {
  background-color: #E1E0FE;
}

.social_about_btn190 {
  background-color: #0B0527;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  width: 300px;
  font-weight: 700;
}

.bar_in_9876253 {
  background-color: #424445;
  width: 5px;
  min-height: 200px;
  opacity: .5;
}

.social_about_part3 {
  font-size: 24px;
}


.cont_about_part3 {
  margin-top: -50px;
}

.about_part3 {
  background-color: #000;
}

/* =========Buy book======== */
.item_info_book {
  background-color: #424445;
}

.item_info_book2 {
  background-color: #FF4E09;
}

.item_info_book2_content {
  padding: 20px;
}

.slider_book_img {
  width: 45px;
}

.jett_buy_book {
  background-color: #FF4E09;
  color: #fff;
  font-weight: bold;
  font-family: "Oswald", Sans-serif;
  padding: 10px;
}

.text_buy_book {
  color: #fff;
}

.price_buy_book {

  font-weight: bold;
  font-family: "Oswald", Sans-serif;
  color: #FFFFFF;
  font-size: 25px;


}

.btn_space_slider_home_page {
  margin-top: -10px;
}

.btn_buy_book {
  border: solid 1px #000;
  background-color: #000;
  color: #FFFFFF;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;

}

.btn_buy_book:hover {
  background-color: transparent;
  color: #FFFFFF !important;
}

.btn_preoreder {
  border: solid 1px #FF4E09;
  background-color: #FF4E09;
  color: #FFFFFF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;
  margin-top: 10px;
}

.btn_preoreder:hover {
  background-color: transparent;
  color: #FFFFFF !important;
}


.text_784_pa {
  font-weight: bold;
}



.btn_buy_book3 {
  border: solid 1px #FF4E09;
  background-color: #FF4E09;
  color: #FFFFFF;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;
  margin: 10px;
}

.btn_buy_book3:hover {
  background-color: #FFFFFF;
  color: #FF4E09 !important;
}

.gif_icon_jett {
  width: 17px;
  border-radius: 50%;
}

.contact_jett {
  background-image: linear-gradient(#44006b, #FF4E09);
}


.p_jett {
  color: #FF4E09;
}

.p_jett:hover {
  color: #FF4E09;
}

.buy_jett_space2 {
  background-color: #EAE7FF;
  border-bottom: solid 1px #a8a8a8;
}

.img_payment_type {
  width: 400px;
}

.separation2345 {
  border-right: solid 2px #dcdbe3;
}

.text90487645 {
  color: #7935FF;
}

.text90487645:hover {
  color: #FF4E09 !important;
}

.image_jett83645 {
  width: 120px;
  margin-bottom: -55px;
}

/* ====================footer================ */

.jett_fa_footer {
  background-color: #FFFFFF;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #ad1fff;
}

.footer_jett {
  background-color: #ad1fff;
  padding: 10px;

}

.footer_a {
  color: inherit;
}



.text1_footer {
  font-size: 15px;
  text-align: center;
  color: #fff;
}

.text2_footer {
  font-size: 28px;
  text-align: center;
  color: #fff;
}

.fa-lg {
  font-size: 25px;
  border: solid 1px #FF4E09;
  background-color: #FF4E09;
  border-radius: 50%;
  padding: 15px;
}

.bi-lg {
  font-size: 18px;
  border: solid 1px #FF4E09;
  background-color: #FF4E09;
  border-radius: 50%;
  padding: 12px;
}

.media_fa {
  color: #FFFFFF;
}

.media_fa:hover {
  color: #FFFFFF !important;
  opacity: .5;
}

.bi_jett {
  color: #FFFFFF;
}


.footer_jett2 {
  background-color: #000;
  padding: 20px;
  margin: auto;
}

.jett_footer_menu li {
  display: inline;
  margin-right: 20px;
}

.jett_footer985 {
  border-bottom: solid 3px #FF4E09;
}

.footer_imager {
  width: 100px;
  margin: 5px;
}

.text3_footer {
  font-size: 24px;
  color: #fff;
  font-family: "Oswald", Sans-serif;
  font-weight: bold;
}

.text4_footer {
  font-size: 16px;
  color: #FF4E09;
  font-family: "Oswald", Sans-serif;
  font-weight: bold;
}

.text5_footer {
  font-size: 14px;
  color: #fff;
  font-family: "Oswald", Sans-serif;

}

.footer_jett78489 {
  font-size: 12px;
  color: #fff;
  font-family: "Oswald", Sans-serif;
}

.bar_footer {
  background-color: #fff;
  height: 1px;
  opacity: .2;
}

.card {
  padding: 2% 7%
}

.social>i {
  padding: 1%;
  font-size: 15px
}

.social>i:hover {
  color: #957bda;
  cursor: pointer
}

.policy>div {
  padding: 4px
}

.heading {
  font-family: 'Titillium Web', sans-serif;
  color: black
}

.divider {
  border-top: 2px solid
}



.input_jett_search {
  display: inline;
  width: 50%;
  padding: 10px;
}



.btn_search {
  float: left;
  display: block;
  font-weight: 700;
  color: #FF4E09;
  margin: 10px;
  padding: 10px;
  border-radius: 25px;
  border: solid 1px #FF4E09;
}

.btn_search:hover {

  background-color: #FF4E09;
  background-color: transparent;
}

/* ==========Books page====== */

.book {
  background-color: #000;
  padding: 30px;
}

.slider_book_imager {
  width: 300px;
}

.slider_book_text1 {
  color: #FFFFFF;
  font-size: 45px;
  font-weight: 700;
}

.slider_book_text2 {
  font-family: 'Shadows Into Light', cursive;
  font-weight: 700;
}

.slider_book_text3 {
  color: #FF4E09;
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  line-height: 1px;
}

.slider_book_text4 {
  color: #FFFFFF;
  font-family: "Oswald", Sans-serif;
  font-size: 33px;
  font-weight: 700;
}

.slider_book_text5 {
  color: #FFFFFF;
  font-weight: normal !important;
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
}

.slider_book_text6 {
  font-family: "Oswald", Sans-serif;
  font-size: 24px;
  margin: 10px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
  opacity: .6;
  margin-top: 10px;
  border: solid 1px #FF4E09;
  color: #000 !important;
  background-color: #FFFFFF;
}

.slider_book_text6:hover {
  color: #FFFFFF !important;
  background-color: #864AFF;
  border: solid 1px #864AFF;
}

.slider_book_text_new {
  font-family: "Oswald", Sans-serif;
  font-size: 24px;
  margin: 10px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
  opacity: .6;
  margin-top: 10px;
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF !important;
}

.slider_book_text_new:hover {
  background-color: #FFFFFF;
  color: #FF4E09 !important;
}

.slider_book_text7 {
  font-style: italic;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  opacity: .5;

}

.slider_book_text8 {
  font-family: "Oswald", Sans-serif;
}


.btn_jett_slider_book {
  background-color: #AFB4FF;
  margin: 10px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #000;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;
  font-weight: 700;

  text-align: center;
  border: solid 1px #AFB4FF;
}

.btn_jett_slider_book:hover {
  background-color: transparent;
  color: #FFFFFF !important;

}

.book_part2 {
  background-color: #DBE9FF;
}

.buy_new_1 {
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  font-size: 55px;
  color: #000;
}
.font_size0948{
  font-size: 45px !important;
}

.buy_new_2 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}

.text_book_part3 {
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  font-size: 45px;
  color: #000;
}

.text_book_part4 {
  font-size: 22px;
  color: #000;
  font-weight: 700;
}

.book_part3_text {
  font-family: "Oswald", Sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000;
}

.book_part3_image848 {
  width: 120%;
}

.book_part3_image848_mobile {
  width: 100%;
}

.book_part3_image_sign {
  width: 150px;
}

.text_sign {
  font-family: "Oswald", Sans-serif;
}

.black_bar_book_down {
  background-color: #000;
  height: 50px;
}

.book_down2 {
  background-color: #FF4E09;
  padding: 25px;
  text-align: center;
  color: #FFFFFF;
  font-size: 35px;
  margin-bottom: -70px;
  font-weight: 700;
}

.book_down_text1 {
  font-size: 30px;
  color: #000;
  font-weight: 700;
}

.book_down_text2 {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;

}

/* ==========++Tour======= */
.tour_text_1 {
  color: #FFFFFF;
  font-size: 40px;
  font-family: "Oswald", Sans-serif;
}

.tour_title_text1 {
  font-size: 24px;
}

.tour_bar {
  border-bottom: solid 3px #ad1fff;
}

.tour_box_date {
  background-color: #ad1fff;
  color: #FFFFFF;

}

.tour_box_event {
  background-color: #ad1fff;
  color: #FFFFFF;
  float: right;
}

.text_enter_event1 {
  font-size: 35px;
}

.text_enter_event2 {
  font-family: "Oswald", Sans-serif;
  font-size: 25px;
}

/* =========Endorsement================ */
.slider_endorse {
  background-color: #000;
}

.img_slider_endorse {
  width: 100%;
}

.text1_slider_endorse {
  color: #FFFFFF;
  font-size: 22px;
}

.text_why_87 {
  color: #FFFFFF;
  font-size: 22px;
}

.text_why_87:hover {
  color: #bdbaba !important;
}

.endorsement_text1 {
  font-family: "Oswald", Sans-serif;
  font-size: 30px;
  font-weight: bold;
}

.endorsement_text2 {
  font-family: "Oswald", Sans-serif;
  font-size: 20px;
}

.jett_supp_met {
  background-color: #535758;
}

.image_endores {
  width: 100%;
  height: 160px;
  object-fit: cover;
  object-position: 50% 50%;
}

.name_endors {
  font-family: "Oswald", Sans-serif;
  text-align: center;
  font-size: 18px;
  margin: 10px;
  color: #FFFFFF;
}

.btn_endors_1 {
  text-align: center;
  border: solid 1px #FF4E09;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #FFFFFF;
  font-weight: 700;
}

.btn_endors_1:hover {
  color: #FFFFFF !important;
  background-color: #FF4E09;

}

.bg_stripe {

  background-color: #FF4E09;
}

.stripe_btn_jett {
  background-color: #ff4e09;
  border-radius: 3px;
  padding: 10px;
  font-weight: 900;
  color: #FFFFFF;
  border: solid 1px #ff4e09;
}

.stripe_btn_jett:hover {
  border: solid 1px #ff4e09;
  background-color: transparent;
  color: #ff4e09;

}

.text_2_endors_101 {
  font-size: 24px;

}

.text_2_endors_105 {
  font-size: 22px;

}

.btn_stripe {
  border: none;
  background-color: transparent;
  padding: 10px;
}

.btn_stripe:hover {
  background-color: #eff4f5;
}

.text_3_endors_101 {
  font-family: "Oswald", Sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 18px;
  margin: 10px;
  color: #000;
  line-height: 25px;
  opacity: .7;
}

.form_endor {
  margin: auto;
}

.cont_input_form_endor {
  margin: 20px;
}

.jett_back_input_speaking {
  background-color: #eff1f3;
}

.input_form_endor {
  width: 100%;
  height: 45px;
  padding-left: 20px;
}

.text_area_form_endor {
  width: 100%;
  height: 85px;
  padding-left: 20px;
}

/* =============+Video================ */
.video_part_cont {
  background-color: #000;
}

.text1_part_cont {
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
}

.text2_part_cont {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
  opacity: .7;
}

.btn_video_part {
  text-align: center;
  border: solid 1px #FF4E09;
  background-color: #FF4E09;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #FFFFFF;
  font-weight: 700;
}

.btn_video_part:hover {
  color: #FFFFFF !important;
  background-color: #FF4E09;

}

.footer_logo_jett {
  width: 120px;
}


/* ================ */
.cont_book_part1_2 {
  margin-top: -50px;
}

.book_part1_2 {
  background-color: #000;
}

.text1_part1_2 {
  color: #FFFFFF;
  font-size: 45px;
}

.text2_part1_2 {
  text-align: center;
  color: #FFFFFF;
  font-family: "Oswald", Sans-serif;
}

.img1_part1_2 {
  border: solid 1px #DCE9FF;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: 50% 50%;
}

.img1_part1_2_new {
  margin-top: 12px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: 50% 50%;
  margin-right: 10px;
}

.border_img1_part1_2 {
  border-right: solid 2px #3b3e40;
}

.btn_buy_book2 {
  border: solid 1px #dee1ef;
  background-color: #dee1ef;
  color: #000;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 17px;
  margin: 10px;
}

.btn_buy_book4 {
  border: solid 1px #000;
  background-color: #000;
  color: #FFFFFF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;

}

.btn_buy_book4:hover {
  background-color: #FFFFFF;
  color: #000 !important;

}

.btn_buy_book5 {
  border: solid 1px #6a39cc;
  background-color: #6a39cc;
  color: #FFFFFF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;

}

.btn_buy_book5:hover {
  background-color: #FFFFFF;
  color: #6a39cc !important;

}

.btn_buy_book2:hover {
  background-color: transparent;
  color: #FFFFFF !important;
}

.sum_book_7645 {
  background-color: #eff7ff;
}

/* =========speaking======= */
.speaking_slider_all {
  background-color: #000 !important;
}

.label_speaking_form {
  margin: 5px;
}

.img_84774 {
  width: 100%;
}

.text1_speaking_slider {
  color: #FF4E09;
  font-weight: 700;
}

.text2_speaking_slider {
  color: #FFFFFF;
  font-size: 30px;
  line-height: 44px;
  font-weight: 700;
}

.text2_1_speaking_slider {
  color: #FFFFFF;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

.text3_speaking_slider {
  font-family: "Oswald", Sans-serif;
  color: #FFFFFF;
}

.speaking_slider_cont1 {
  padding: 50px;
}

.btn_speaking_slider {
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: #FF4E09;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
}

/* ======================== */
.text1_fund {
  color: #000;
  font-family: "Oswald", Sans-serif;
  text-align: center;
}

.text2_fund {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.fund_div_cont {
  background-color: #dbe9ff;
  padding: 10px;
}

.fund_header {
  background-color: #FFFFFF;
  padding: 15px;
  text-align: center;

}

.text2_fund_001 {
  font-family: "Oswald", Sans-serif;
  text-align: center;
  font-weight: bold;
}

.img_fund {
  width: 100%;
}

.btn_fund_down {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  margin: 15px;
  padding: 10px;
  width: 200px;
  font-size: 20px;
  font-weight: 700;
}

.btn_fund_down:hover {
  background-color: transparent;
  color: #FF4E09;

}

.fund_black_sect {
  background-color: #000;
  color: #FFFFFF;
  padding: 10px;
}

.fund_black_001_text1 {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
  margin-top: 15px !important;
}

.cont1_fund_black_sect_text {
  padding: 40px;
}

.text2_part3003 {
  text-align: center;
  font-size: 30px;
  color: #000;
  font-weight: 700;
  margin-top: 30px;
}

/* ======buy page ======*/
.buy_7585 {
  margin-top: 40px;
}

.buy_text56 {
  font-weight: 700;
}

.cont1_buy {
  margin: auto;
}

.buy_image001 {
  width: 300px;

}

.int_cont1_buy {
  background-color: #000;
  padding-top: 30px;
}

.int_cont2_buy {
  background-color: #FAF7FF;
}

.bar_cont1_buy {
  background-color: #7935FF;
  color: #FFFFFF;
  padding: 20px;

}


.bar_cont2_buy {
  background-color: #FF4E09;
  color: #FFFFFF;
  padding: 20px;

}

.text_bar_cont1_buy {
  display: inline;
  font-size: 20px;
  margin: 30px;
}

.buy_input_98 {
  color: #424445;
  width: 100px;
  text-align: center;
  border: none;
}

.cont_cent_buy_7468 {
  background-color: #EAE7FF;
  padding: 25px;

}

.cont2_cent_buy_7468 {
  border: solid 1px #d8d3d3;
  padding: 40px;
  border-radius: 10px;
}

.table_buy_345 {
  width: 100%;
}

.table_buy_345 td {
  padding-bottom: 15px;
}

.text_tax_buy {
  font-family: "Oswald", Sans-serif;
}

.total_buy {
  font-size: 25px;
  line-height: 12px;
}

.text_area_buy_page {
  width: 100%;
  height: 100px;
  background-color: #f0f0f0;
  border: solid 1px #d8d3d3;
  border-radius: 10px;
  font-family: "Oswald", Sans-serif;
  padding: 10px;
}

.btn_buy_now {
  background-color: #7935FF;
  color: #FFFFFF;
  padding: 15px;
  border: solid 1px #7935FF;
  border-radius: 25px;
  font-weight: 700;
}

.btn_buy_now:hover {
  background-color: #f0f0f0;
  color: #7935FF;
}

.btn_buy_now2 {
  background-color: #FF4E09;
  color: #FFFFFF;
  padding: 15px;
  border: solid 1px #FF4E09;
  border-radius: 25px;
  font-weight: 700;
}

.btn_buy_now2:hover {
  background-color: #f0f0f0;
  color: #FF4E09;
}

.cont2_bar2 {
  background-image: linear-gradient(to right, #FFFFFF, #FF4E09, #FF4E09, #FF4E09, #FF4E09);
  padding: 10px;
  text-align: center;
}

.image_cont2_bar2 {
  width: 150px;
  margin-bottom: -100px;
}

.cont763658 {
  color: #FFFFFF;
  padding-left: 50px;
  font-weight: 700;
  font-size: 40px;
}

.cont2_part2_84765 {
  background-color: #000;
  padding: 8px;
}

.cont1_part2_price {
  background-color: #DFE1EF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 18px;
}

.payment_image_accept {
  width: 60%;
}

.payment_image_accept2 {
  width: 60%;
  margin-top: -5px;
}

.payment_cont984 {
  background-color: #f0f0f0;
  padding: 10px;
}

.last_payment_page984 {
  background-color: #FAF7FF;
}

.title_last_payment {
  color: #7935FF;
}

.text_last_payment {
  color: #000;
  margin: 5px;
  font-size: 14px;
  font-family: "Oswald", Sans-serif;
}

.buy_part3_jett98 {
  background-color: #000;
}

.image763437 {
  width: 200px;
}

.image763437984 {
  width: 100%;
}

.bg_84763543 {
  background-color: #0B0527;
  height: 150px;
}

.exclusive {
  background-color: #000;
  padding: 10px;
  font-weight: 700;
  color: #FFFFFF;
}

.new_buy001 {
  font-weight: 700;
  font-size: 27px;
  background-color: #FE5011;
}

.new_buy001_box1 {
  border-right: solid 3px #ebe4e4;
}

.color_main_jaune {
  color: #FE5011 !important;
}

.exclusive_i {
  font-size: 30px;
}

.new_buy001_text1 {
  border-bottom: solid 1px #E8A173;
}

.new_buy001_text2 {
  font-weight: 400 !important;
  font-size: 18px;
  margin-top: 5px;
}

.buy_por984774_text1 {
  font-weight: 700;
  font-size: 50px;
  color: #000;
  text-align: center;
  line-height: 45px;
}

.buy_por984774_text2 {

  font-size: 18px;
  color: #000;
  text-align: center;
}

.buy_box1 {
  background-color: #0B0527;
  border-radius: 25px;
}

.buy_box_text1 {
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 22px;
}

.buy_box_btn {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  padding: 5px;
  font-weight: 700;
  border-radius: 20px;
}

.buy_box_btn:hover {
  background-color: transparent;
  color: #FF4E09 !important;
}

.img_buy_footer123 {
  width: 600px;
}

/* ===================== */
.slider_donate {
  background-color: #000;
  padding: 10px;
}

.text1_slider_donate {
  color: #FFFFFF;
}

.text2_slider_donate {
  color: #FF4E09;
}

.input_somme_jett {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #CED6F6 !important;
  border: none;
  height: 45px;
  font-weight: bold;
  font-size: 22px;
  color: #000;
  text-align: center;
  background: url("../../public/images/icons/dollar2.png") no-repeat left;
  background-size: 20px;
  background-position: 15% 56%;

}

.input_somme_jett_new {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #CED6F6;
  border: none;
  height: 35px;
  font-weight: 700;
  font-size: 22px;
  color: #000;
  text-align: center;
  background: url("../../public/images/icons/dollar2.png") no-repeat left;
  background-size: 20px;
  background-position: 15% 56%;
}

.donate_bg_99 {
  border: solid 1px #000;
  width: 300px;
}

.btn1_jett_89 {

  background-color: #FF4E09;
  color: white;
  padding: 8px 15px 8px 15px;
  font-weight: 700;
  text-align: center;
  border: solid 1px #FF4E09;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.btn1_jett_89:hover {
  background-color: white;
  color: #FF4E09;
  ;
}

.donate_bar2 {
  background-image: linear-gradient(to right, #FFFFFF, #FFFFFF, #FF4E09, #FF4E09, #FF4E09, #FF4E09, #FF4E09, #FF4E09);
  padding: 10px;
  text-align: center;
}

.donate_bar3 {
  background-color: #FF4E09;
  padding: 10px;
  text-align: center;
}

.image_donate_bar2 {
  width: 350px;
  margin-bottom: -200px;
}

.donate_bar_text2 {
  font-family: "Oswald", Sans-serif;
  font-size: 14px;
}

.donate_bg_98 {
  width: 300px;
}

.donate_bg_99 {
  border: solid 1px #000;
  width: 300px;
}

.lee_book {
  background-color: #D3EBFF;
  color: #000;
}

.pdf_ebook {
  width: 80%;
  height: 700px;
}


.mail-seccess {
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
}

.mail-seccess .success-inner {
  display: inline-block;
}

.mail-seccess .success-inner h1 {
  font-size: 60px;
  text-shadow: 3px 5px 2px #3333;
  color: #27A744;
  font-weight: 700;
}

.mail-seccess .success-inner h1 span {
  display: block;
  font-size: 25px;
  color: #333;
  font-weight: 600;
  text-shadow: none;
  margin-top: 5px;
}



.mail-seccess .success-inner .btn {
  color: #fff;
}

.jett_bg_pl {
  border: solid 1px;
  padding: 20px;
}

.dropZoneContainer {
  margin-left: 85px;
}

@media only screen and (max-width: 800px) {
  .dropZoneContainer {
    margin-left: 130px;
  }

}


.dropZoneOverlay,
.FileUpload {
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  cursor: pointer;

}

.dropZoneOverlay {

  position: absolute;
  top: 0px;

}

.FileUpload {
  opacity: 0;
  position: relative;
  z-index: 1;
}

.input_don_jett {
  border-radius: 0px;
  box-shadow: none;
  opacity: .7;
}

.btn_cont {
  border: solid 1px transparent;
  background-color: transparent;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 50px;
  padding-right: 50px;
  color: #864AFF;
  font-weight: 700;
}

.btn_cont:hover {
  border: solid 1px;
  border-radius: 25px;

}





.jett87345_1 {
  font-family: "Oswald", Sans-serif;
}

.jett87345_2 {
  font-weight: bold;

}

.text_jett735428 {
  font-family: "Oswald", Sans-serif;
  font-weight: bold;
  opacity: .5;
}

/* =============== */
.transp_text1 {
  font-size: 27px;
  color: #FFFFFF;
}

.fund_cont2_text1 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.fund_cont2_text2 {
  color: #000;
  font-family: "Oswald", Sans-serif;
}

.fund_cont2_text3 {
  color: #000;
  font-weight: bold;
  font-family: "Oswald", Sans-serif;
}

.fund_cont2_part1 {
  border-right: solid 1px #c7bfbf;
}

.btn_funt1 {
  background-color: #000;
  color: #FFFFFF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

.btn_funt2 {
  background-color: #FF4E09;
  color: #FFFFFF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}



.img_trans_001 {
  width: 100%;
}



.espace_def_pay_jett846 {
  background-color: #5D24CA;
}

.espace_def_pay_text {
  color: #fff;
  font-size: 33px;
  font-weight: bold;
}

.content_espace_def {
  background-color: #752BFD;
  opacity: 0.9;
  border-radius: 8px 8px 8px 8px;
  padding: 15px;
}

.text_espace_def {
  color: #A7A4CEF5;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
}

.btn_jett_espace_def {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  fill: #060620BF;
  color: #060620BF;
  background-color: #CED6F6;
  border: solid 1px #CED6F6;
  border-radius: 50px 50px 50px 50px;
  padding: 5px 9px 5px 9px;
}

.btn_jett_espace_def:hover {
  cursor: pointer;
  background-color: white;
  border: solid 1px #CED6F6;
}

.cont_fund_3 {
  background-color: #CED6F6;
}

.fund_text1_986554 {
  color: #6f6d6d;
}

.input_donner_iterm_jett {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #fff;
  border: none;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  padding-left: 20px;
}

.btn_donner_iterm_jett {
  background-color: #FF4E09;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
}

.btn_donner_iterm_jett:hover {
  background-color: transparent;
  color: #FF4E09;
}

.cont_jet_8653 {
  padding: 30px;
}

.p_uyel8474 {
  font-family: "Oswald", Sans-serif;
  padding: 10px;
  line-height: 20px;
  color: #000;
}

.img_board985 {
  width: 100%;
}

/* ============= */
.comm_text1 {
  font-size: 18px;
  font-weight: 700;
  border-right: solid 1px #FFFFFF;
}

.comm_text2 {
  font-size: 14px;
  margin-top: -5px;
}

.jkl987 {
  margin-bottom: -60px;
}

.resume_book {
  background-color: #000;
}

.pdf_res001 {
  width: 100%;
  height: 900px;
}

/* == */
.support_home {
  background-color: #E9EDEE;
  padding: 10px;
  border-bottom: solid 1px #e79c7e;
}

.support_home_cont {
  border-bottom: solid 2px #000;

}

.text1_support_home {
  font-size: 34px;
  line-height: 40px;
  background-color: #000;
  color: #FFFFFF;
  font-weight: 700;

}

.img_support_home {
  border-radius: 50%;
  width: 100px;
  height: 90px;
  object-fit: cover;
  object-position: 50% 50%;
}

.home_new_text1 {
  color: #000;
  font-weight: bold;
}

.home_new_text2 {
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  border-right: solid 1px #000;
  margin-top: 10px;
}

.home_new_text4 {
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  border-right: solid 1px #FFFFFF;
  margin-top: 10px;
  font-weight: 700;
}

.home_new_text3 {

  font-family: "Oswald", Sans-serif;
  font-weight: 400;
}

.home_new_text13 {
  font-weight: bold;
}

.btn_home_jett_8000 {
  font-size: 20px;
  line-height: 40px;
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
}

.btn_home_jett_8000:hover {
  color: #FFFFFF !important;
  background-color: transparent;
}

.marg_top_jett_8347 {
  margin-top: 40px;
}

.btn_home_jett_7864 {
  font-size: 19px;
  line-height: 25px;
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  padding: 5px 20px;
  display: flex;
}

.btn_home_jett_7864:hover {
  color: #000 !important;
  background-color: transparent;
}

.btn_home_jett_7864_2 {
  font-size: 20px;
  line-height: 40px;
  background-color: #000;
  border: solid 1px #000;
  color: #FFFFFF;
  font-weight: 700;
}

.btn_home_jett_7864_2:hover {
  color: #000 !important;
  background-color: transparent;
}

.btn_home_jett_7864_3 {
  font-size: 20px;
  line-height: 40px;
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FFFFFF;
  font-weight: 700;
}

.btn_home_jett_7864_3:hover {
  color: #FF4E09 !important;
  background-color: #FFFFFF;
}

.btn_home_jett_7864_4 {
  font-size: 20px;
  line-height: 40px;
  background-color: #5e17eb;
  border: solid 1px #5e17eb;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FFFFFF;
  font-weight: 700;
}




.home_new_jett_text1 {
  font-family: "Oswald", Sans-serif;
  font-weight: bold;
  color: #000;
}

.part_jett_home_new10 {
  margin-bottom: -40px;
  background-color: #000;
  position: relative;
  color: #FFFFFF;
  padding: 20px;
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
}

.part_jett_home_new1 {
  background-color: #EAE7FF;
}

.iframe_part_2 {
  width: 100%;
  height: 400px;
}

.content_video_2 {
  border: solid 14px #8028FE;
}

.pkl_908 {
  margin-top: -50px;
}

.bg_jett_new98 {
  background-color: #FF4E09 !important;
}

.space_jett98_b {
  margin-top: -10px;
}

.btn_space_jett98_b {
  background-color: transparent;
  font-size: 18px;
  color: #FFFFFF;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  border: solid 1px #FF4E09;
}

.btn_space_jett98_b:hover {
  background-color: #FF4E09;
  color: #FFFFFF !important;
}

.btn2_space_jett98_b {
  color: #FF4E09;
  font-size: 20px;
}

.btn2_space_jett98_b2 {
  color: #6A39CC;
  font-size: 20px;
}

.no_affect_link {
  color: inherit;
}

.new_jett_part_book_34 {
  margin-top: -21px;
}

.jett_text_7464 {
  font-size: 45px !important;

}

.new_cartact_jett_001 {
  margin-top: -23px;
  ;
}

/* ============ */

.board_menber {
  background-color: #CED6F6;
  padding: 5px;
}


.img_board_menber {
  border: solid 1px #c4bcbc;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  object-fit: cover;
  object-position: 50% 50%;
}

.board_menber_text1 {
  font-size: 40px;
  color: #000;
}

.board_menber_text2 {
  font-family: "Oswald", Sans-serif;
  opacity: .5;
  font-size: 18px;
  padding: 10px;
}

.board_menber_text3 {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;

}

/* =============== */
.new_about {
  background-color: #FF4E09;
  padding: 20px;
}

.new_contact {
  background-color: #864AFF;
  padding: 20px;
}

.new_contact2 {
  background-color: #000;
  padding: 20px;
}

.new_about_text1 {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 700;
}

.new_about_text2 {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
  color: #FFFFFF;
}

.new_about_part1 {
  border-bottom: solid 1px #E8A173;
  padding-bottom: 10px;
}

.new_about_part3 {
  border-bottom: solid 1px #333232;
  padding-bottom: 10px;
}

.bi_1936 {
  width: 40px;
}

.img_7464 {
  width: 70px;
  margin-top: -17px;
}

.img_746400 {
  width: 120px;
}

.new_about_email_text {
  color: #000;
  padding-left: 10px;
  font-size: 18px;
}

.new_about_part2_box1 {
  background-color: #000;
  color: #FFFFFF;
  padding: 10px;
  font-weight: 700;
}

.new_about_part2_box2 {
  background-color: #FFFFFF;
  color: #000;
  padding: 10px;
  font-weight: 700;
}

.new_about_bar_menu {
  background-color: #25222E;
  padding: 20px;
}

.new_about_bar_menu_btn {
  color: #a2a4af;
  font-size: 18px;
  border: solid 1px #525050;
  padding: 10px;
  width: 340px;
  display: block;
  font-weight: 700;
}

.new_about_bar_menu_btn:hover {
  color: #5d5a5a !important;

}

.new_about_double_box_1 {
  background-color: #FF4E09;
  padding: 20px;
}

.new_about_double_box_1_text1 {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 700;
}

.new_about_double_box_1_text2 {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
}

.img_746987 {
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 60px;
}


.new_about_double_box_2 {
  background-color: #F5F1FF;
  padding: 20px;
}

.new_about_bar2 {
  background-color: #000;
  padding: 30px;
  margin-top: -140px;
}

.new_about_double_part2 {

  background-color: #EAE7FF;
  margin-top: -7px;
}

.new_about_double_part3 {
  background-color: #EAE7FF;
  margin-top: -7px;
  padding: 10px;
}

.new_about_double_part3_border {
  border-right: solid 1px #6c6868;
}

.image_ressource_jett_part {
  width: 100%;
  margin: 15px;
}

/* ========= */
.quote_part_box1 {
  background-color: #F5F1FF;
  padding: 30px;
  color: #000;
  font-family: "Oswald", Sans-serif;
  text-align: start;
}

.quote_part_box1_text1 {
  font-weight: bold;
}

.quote_part_box1_text2 {
  padding: 10px;
  font-family: "Oswald", Sans-serif;
  color: #000;
  text-align: start;
  line-height: 25px;
}

/*============ */
.podcast_ayi_kapab {
  background-image: url("../../public/images/podcast/ayi_kapab.png");
  background-color: #cccccc;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.podcast_on_fire {
  background-image: url("../../public/images/9_sep_img/gopdate4.png");
  background-color: #cccccc;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.podcast_box {
  background-color: #5E18EA;
  padding: 20px;
  text-align: center;

}

.icon_0098 {
  width: 50px;
}

.podcast_box22 {
  background-color: #FF4E09;
  padding: 20px;
  text-align: center;
}

.podcast_box22 {
  background-color: #000;
  padding: 20px;
  text-align: center;
}

.podcast_box2_slider {
  background-color: #FF4E09;
  padding: 20px;
  text-align: center;
  height: 380px;
}


.btn1_podcast_box {
  font-size: 20px;
  line-height: 40px;
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
}

.btn1_podcast_box:hover {
  color: #FFFFFF !important;
  background-color: transparent;
}

.podcast_part2 {
  background-color: #EAE7FF;

}

.img_podcast1 {
  width: 100%;
}


.podcast_all {
  background-color: #EAE7FF;
}

.podcast_part3 {
  background-color: #000;
  padding: 30px;
}

.podcast_part3_1 {
  background-color: #000;
  padding: 30px;
  border-top: solid 4px #FF4E09;
}

.podcast_part3_box1 {
  background-color: #5E18EA;
  color: #FFFFFF;
  font-size: 30px;
  padding: 10px;
  font-weight: 700;
}

.podcast_part3_box1_other {
  background-color: #FF4E09;
  color: #FFFFFF;
  font-size: 30px;
  padding: 25px;
  font-weight: 700;
}

.img_podcast2 {
  width: 170px;
}

.podcast_part3_text1 {
  font-size: 50px;
  color: #FFFFFF;
}

.podcast_part3_text2 {
  font-size: 20px;
  color: #FFFFFF;
  margin-top: 10px;
}

.podcast_form_content {
  background-color: #F5F1FF;
  padding: 50px;
}

.podcast_form {
  background-color: #FFFFFF;
  padding: 30px;

}

.podcast_form_text1 {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;

}

.podcast_form_text2 {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
  opacity: .7;
}

.label_pod_text {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.input_podcast {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 15px;
  border-radius: 15px;
  border: solid 1px #d7d1d1;
  font-family: "Oswald", Sans-serif;
}

/* ================= */
.way_to_contribute_box1 {
  background-color: #5E18EA;
  color: #FFFFFF;
  font-size: 35px;
  padding: 35px;
  font-weight: 700;
}

.way_to_contribute_box2 {
  background-color: #FF4E09;
  color: #FFFFFF;
  font-size: 35px;
  padding: 35px;
  font-weight: 700;
}

.way_to_contribute_box3 {
  background-color: #008D8D;
  color: #FFFFFF;
  font-size: 35px;
  padding: 35px;
  font-weight: 700;
}

.way_to_contribute_box4 {
  background-color: #BB0081;
  color: #FFFFFF;
  font-size: 35px;
  padding: 35px;
  font-weight: 700;
}

.way_to_contribute_box5 {
  background-color: #2918EA;
  color: #FFFFFF;
  font-size: 35px;
  padding: 35px;
  font-weight: 700;
}

.img_way_to_contribute {
  width: 250px;
}

.way_to_contribute_box1_text1 {
  font-family: "Oswald", Sans-serif;
  color: #FFFFFF;
  font-size: 18px;
}

/* ========= */

.contact_box1 {
  background-color: #5E18EA;
  color: #FFFFFF;
  font-weight: 700;
  padding: 10px;
}


.contact_btn1 {
  color: #FFFFFF;
  font-size: 18px;
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  padding: 10px;
  width: 340px;
  display: block;
  font-weight: 700;
}

.contact_btn1:hover {
  color: #FF4E09 !important;
  background-color: transparent;

}

.contact_part2_box1 {
  border-right: solid 3px #454343;
  padding: 5px;
  height: 130px;
}

.contact_part2_box2 {

  padding: 5px;
}

.contact_part2_text1 {
  font-family: "Oswald", Sans-serif;
  color: #9d9595;
  font-size: 18px;
}

.contact_page {
  background-color: #EAE7FF;
}

.contact_part3_text1 {
  font-size: 35px;
  padding: 5px;
  text-align: center;
  color: #000;
  line-height: 20px;
  font-weight: 700;
}

.contact_part3_text2 {
  padding: 5px;
  text-align: center;
  font-family: "Oswald", Sans-serif;
  color: #000;
  font-size: 18px;
}

.text_jett9874537 {
  text-align: left;
}

.contact_part3_box1 {
  background-color: #CACEE8;
}

.contact_part3_box2 {
  padding: 10px;
}

.contact_part3_img_logo {
  width: 100%;
}

.contact_part3_box3 {
  background-color: #EAE7FF;
  padding: 10px;
}

.contact_part3_btn1 {
  float: inline-end;
  background-color: #000;
  color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: -50px;
  margin-right: -10px;
  border: solid 1px #000;
  font-weight: 700;
}

.contact_part3_btn1:hover {
  background-color: transparent;
  color: #000;

}

.contact_part4 {
  background-color: #FF4E09;
  color: #FFFFFF;
  font-size: 40px;
  text-align: center;
  padding: 25px;
}

.bar_bleu001 {
  background-color: #5E18EA;
}

.img_fund_5986 {
  width: 100%;
}

.content_img_about {
  border: solid 5px #FF4E09;
}

/* ==============SCROLL============= */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  border-radius: .9em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D4DFE1;
  border-radius: .9em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5d18ea;
  border-radius: .9em;
}


.jett_scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

.jett_scrollmenu_in {
  display: inline-table;
  padding: 5px;
  text-decoration: none;

}


/* =========================== */
.text1_slider_donate_new {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 700;
}

.text2_slider_donate_new {
  color: #FFFFFF;
}

.donate_part2 {
  background-color: #F5F1FF;
}

.donate_part2_text1 {
  color: #000;
}

.bonate_btn1 {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  padding: 6px;
}

.bonate_btn1:hover {
  background-color: transparent;

  color: #FF4E09 !important;

}

.border_bar_donate {
  border-right: solid 2px #b0b8c4;
}

.bonate_btn2 {
  background-color: #000;
  border: solid 1px #000;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  padding: 6px;
}

.bonate_btn2:hover {
  background-color: transparent;
  border: solid 1px #000;
  color: #000 !important;

}

.donate_bottom_text {
  color: #000;
  font-weight: 700;
  font-size: 30px;
}

.bar_payment {

  height: 1px;
  background-color: #464444;
}

/* ===== */
.impact_box_part_cont {
  background-color: #D4D8F6;
}

.img_podcast_recent {
  width: 100%;
  border: solid 5px #FFFFFF;
}

.icon_podcast_recent {
  width: 45px;
}

.bar_podcast_recent {
  background-color: #AAAAAA;
  height: 1px;
  width: 70%;
  margin: 5px;
}

.text1_podcast_recent {
  font-size: 21px;
  text-align: center;
  color: #000;
  font-weight: 700;
  white-space: normal;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.text2_podcast_recent {
  font-family: "Oswald", Sans-serif;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #000;
  opacity: .7;
  white-space: normal;
}

.bar_separation1_podcast {
  background-color: #5E18EA;
  height: 10px;
}

.eapce95875 {
  padding-left: 20px;
}

.contact_text10 {
  background-color: #000;
  font-size: 34px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
}

.mobile_btn09 {
  display: inline;
}

.login_text_1 {
  color: #FF4E09;
}

.reaction_bg_img {
  background-image: url("../../public/images/img/reaction.png");
  background-color: #cccccc;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 400px;

}

.reaction_header_1 {
  background-color: #2C3E4F;
  font-size: 40px;
  color: #bcb7b7;
  padding: 20px;
  font-weight: 700;
  text-align: center;
}

.reaction_header_down {
  font-size: 14px;
  color: #FFFFFF;
}

.reaction_header_down_in {
  color: #ff4e09;
}

.reaction_header_2 {
  background-color: #000;
  font-size: 40px;
  color: #FFFFFF;
  padding: 20px;
  font-weight: 700;
  text-align: center;

}


.reaction_box1 {
  background-color: #FF4E09;
  color: #FFFFFF;
  padding: 30px;
  margin-top: -50px;

}

.reaction_text3 {
  font-weight: 700;
  font-size: 22px;
}

.reaction_box2 {
  background-color: #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  padding: 20px;
}

.reaction_box3 {
  background-color: #F5F1FF;
  padding: 20px;
}

.reaction_box3_text1 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.reaction_box3_1 li {
  margin-bottom: 10px;
  color: #000;
}

.reaction_box3_1_text1 {
  font-weight: 700;
}

.reaction_link {
  color: #6a39cc;
}




/* ================== */




.persist_img_page8474 {
  width: 100%;
}

/* ========= */

.book_mobile_image_content {
  height: 700px;
}

.image_book_mobile {
  width: 100%;
}

.scroll_page_jett {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 22px;
  padding: 15px;
}

.table_scroll {
  width: 100%;
}


/* =========+BLOG============== */


.content_blog {
  border-top: solid 4px #6d7277;
}

.content_sidebar {
  border-right: solid 2px #6d7277;
}

.content_sidebar_contenue {
  padding: 20px;
}

.bar1_sidebar {
  background-color: #ff8c00;
  height: 10px;
}

.bar2_sidebar {
  background-color: #FFB400;
  height: 10px;
}

.blog_scrol_jett {
  overflow: scroll;
  height: 690px;
}

.image_circle_blog {
  border: solid 1px #DCE9FF;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: 50% 50%;
}

.title1_blog {
  font-size: 30px;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  color: #000;
}

.content2_sidebar {
  padding: 10px;
}

.table_blog {
  width: 100%;
}

.search_text {
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  color: #000;
}

.input2 {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}


.input_blog {
  border: solid 2px #000;
}

.btn1 {
  font-size: 18px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn_blog {
  background-color: #000;
  color: #FFFFFF;
  border: solid 2px #000;


}

.btn_blog:hover {
  background-color: transparent;
  color: #000;
}


.input_blog2 {
  border: solid 2px #ffb901;
}

.btn_blog2 {
  background-color: #ffb901;
  color: #FFFFFF;
  border: solid 2px #ffb901;
}

.btn_blog2:hover {
  background-color: transparent;
  color: #000;
}

.input_blog3 {
  border: solid 2px #2C3E4F;
}

.btn_blog3 {
  background-color: #2C3E4F;
  color: #FFFFFF;
  border: solid 2px #2C3E4F;
}

.btn_blog3:hover {
  background-color: transparent;
  color: #000;
}

.text2_blog {
  font-size: 16px;
  opacity: .7;
}

.blog_title1_main {
  font-size: 30px;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  color: #000;
}

.blog_text1_main {
  font-size: 18px;
  font-family: "Oswald", Sans-serif;
  color: #000;
}

.bar_post_date {
  border-top: solid 1px #000;
  opacity: .7;
  padding-top: 3px;

}

/* ============Profil ====================*/

.profil_jett {
  margin: auto;
}


/* ===============animation_jett============= */






.slider {

  overflow: visible;
  position: relative;
}

.mask {
  overflow: hidden;
  height: 100px;
}

.slider ul {
  margin: 0;
  padding: 0;
  position: relative;
}

.slider li {
  width: 680px;
  height: 320px;
  position: absolute;
  top: -325px;
  list-style: none;
}

.jett87454 {
  margin-left: 12px
}

.slider .source {
  font-size: 20px;
}

.slider li.anim1 {
  animation: cycle 35s linear infinite;
}

.slider li.anim2 {
  animation: cycle2 35s linear infinite;
}

.slider li.anim3 {
  animation: cycle3 35s linear infinite;
}

.slider li.anim4 {
  animation: cycle4 35s linear infinite;
}

.slider li.anim5 {
  animation: cycle5 35s linear infinite;
}

.slider li.anim6 {
  animation: cycle6 35s linear infinite;
}

.slider:hover li {
  animation-play-state: paused;
}

@keyframes cycle {
  0% {
    top: 0px;
  }

  4% {
    top: 0px;
  }

  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  20% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }

  21% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }

  50% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }

  92% {
    top: -325px;
    opacity: 0;
    z-index: 0;
  }

  96% {
    top: -325px;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes cycle2 {
  0% {
    top: -325px;
    opacity: 0;
  }

  16% {
    top: -325px;
    opacity: 0;
  }

  20% {
    top: 0px;
    opacity: 1;
  }

  24% {
    top: 0px;
    opacity: 1;
  }

  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  40% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }

  41% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle3 {
  0% {
    top: -325px;
    opacity: 0;
  }

  36% {
    top: -325px;
    opacity: 0;
  }

  40% {
    top: 0px;
    opacity: 1;
  }

  44% {
    top: 0px;
    opacity: 1;
  }

  56% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  60% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }

  61% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle4 {
  0% {
    top: -325px;
    opacity: 0;
  }

  56% {
    top: -325px;
    opacity: 0;
  }

  60% {
    top: 0px;
    opacity: 1;
  }

  64% {
    top: 0px;
    opacity: 1;
  }

  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  80% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }

  81% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle5 {
  0% {
    top: -325px;
    opacity: 0;
  }

  76% {
    top: -325px;
    opacity: 0;
  }

  80% {
    top: 0px;
    opacity: 1;
  }

  84% {
    top: 0px;
    opacity: 1;
  }

  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  100% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
}


.tiktok_jett {
  width: 12px;
  color: #FF4E09;
}

.space0098 {
  margin-top: 1px;
}

.image_icon_video {
  width: 35px;
  margin-top: -6px;
}

.jett_download {
  font-size: 25px !important;
}

/* ========== */

/* Styles for the card input container */
.CardElement {
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Styles for the card input when it's in focus */
.CardElement--focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Styles for the card input when there's an error */
.CardElement--invalid {
  border-color: #dc3545;
}

/* Styles for the card input's placeholder text */
.CardElement::placeholder {
  color: #6c757d;
}

/* Styles for the card input's valid text */
.CardElement--complete::placeholder {
  color: #28a745;
}

/* ==================== */
.jett_supporter_div {
  margin-left: 100px;
  margin-top: -15px;
}

.jett_supporter_a {
  color: #000;
  opacity: .4;
  font-size: 15px;

}

.jett_supporter_a:hover {
  opacity: 1;

}

.space8479l {
  margin-top: -40px;
}

/* .modal-body {
  max-height: 700px; 
  overflow-y: auto; 
}
 */

.top-container {
  display: flex;
  align-items: center;
}

.profile-image {
  border-radius: 10px;
  border: 2px solid #5957f9;
}

.name {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.name2 {
  font-size: 16px;
  position: relative;
  top: 8px;
}

.bg_comment {
  background-color: #E4F7FF;
  padding: 2px 10px 10px 10px;
  border-radius: 15px;
}

.mail {
  font-size: 14px;
  color: #000;
  position: relative;
  top: 2px;
}

.mail2 {
  font-size: 12px;
  color: grey;
  position: relative;
  margin-top: 22px;
  opacity: .7;

}

.mail3 {
  font-size: 14px;
  color: grey;
  position: relative;
  margin-top: -15px;

}

.bg_12904 {
  background-color: #A0D7FF;
}

.btn_create_new {
  background-color: #FFFFFF;
  border: solid 1px #FFFFFF;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;
  color: #000;
  font-size: 17px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0 5px 2px -2px #C0B6DB;
}

.bar_unlog {
  background-color: #325066;
  padding: 10px;
}


.bar_unlog_text1 {
  color: #E4F7FF;
  font-size: 23px;
}

.line_bar {
  background-color: #E4F7FF;
  height: 1px;
  opacity: .3;
  width: 200px;
  margin-top: 4px;
}

.bar_unlog_text2 {
  color: #E4F7FF;
  font-size: 12px;
  opacity: .5;

}

.no_post {
  border: solid 5px #FFBD59;
  padding: 20px;
  font-size: 18px;
  background-color: #FFFFFF;
}

.bg_endor_box1 {
  background-color: #e9edee;
  border: solid 1px #989fa0;
  padding: 10px;
}

.endorse_right {
  float: right;
  margin-top: 35px;
}


.btn_endorse_list {
  background-color: #FFBD59;
  border: solid 1px #FFBD59;
  text-decoration: none;
  color: #000;
  font-size: 14px;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
}

.btn_endorse_list:hover {
  background-color: #FFFFFF;
}

.icon_image {
  width: 25px;
}


.modal_post_btn {
  text-decoration: none;
  color: inherit;


}

.modal_post_box1 {
  border: solid 1px #A0D7FF;
}

.modal_post_box2 {
  background-color: #A0D7FF;
  border: solid 1px #A0D7FF;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters>[class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.load_img {
  width: 60px;
  margin-top: 50px;
}

.user_image1_1 {
  border: solid 1px #DCE9FF;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: 50% 50%;
}


.text2_part22 {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
  padding-bottom: 20px;
}




/* message buy css */
.right-bottom-div {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 10px 20px;
  width: 400px;
}


.m-34-text-bg {
  background-color: #ff4e09;
  padding: 10px;
  border-radius: 5px;
}

.m-34-text-bg2 {
  background: linear-gradient(to right, #ff4e09, #FFFFFF, #FFFFFF);
  padding: 5px;
  border-radius: 5px;

}

.text-mess-1 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.text-mess-2 {
  font-size: 17px;
  font-weight: bold;
  opacity: .7;
  margin-top: 7px;
}

.text-mess-3 {
  font-size: 17px;
}

.img-right-bottom-div {
  width: 140px;
  float: left;
  margin-top: -19px;
}

.img-time-left {
  width: 22px;
}



/* LimitedTimeOffer.css */

.offer-container {
  text-align: center;
  margin: 10px;
  padding: 20px;
  border: 2px solid #ff4e09;
  border-radius: 10px;
  background-color: #fff0f0;
}

.offer-title {
  font-size: 25px;
  color: #ff4e09;
  margin-bottom: 5px;
}

.offer-description {
  font-size: 20px;
  color: #000;
  margin-bottom: 0px;
}


/* ======= */

.container_video {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.spread_text1 {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
}

.spread_text2 {
  text-align: center;
}

.img_spread_text2 {
  width: 230px;
}

.spread_btn {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  font-weight: 900;
  display: block;
  color: #000;
}

.spread_box1 {
  background-color: #E34F12;
  color: #FFFFFF;
  font-weight: 800;
  padding: 10px;
}

.spread_box_title {
  font-size: 15px;
}

.spread_box_video {
  background-color: #e34f12;

}

.spread_box_video_title {
  color: #FFFFFF;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
}

/* ======= */
.header_time {
  background-color: #000;

  border-bottom: solid 1px #FFFFFF;
}

.img_time {
  width: 80px;
}

.border8759 {
  border-left: solid 3px;
  padding-left: 25px;
}

.time_text1 {
  font-weight: 900;
  font-size: 26px;
  color: #E34F12;
  line-height: 30px;
}

.time_text2 {
  font-weight: 900;
  font-size: 16px;
  line-height: 25px;
}

.time_text3 {
  color: #f7f067;
  font-weight: 900;
  font-size: 16px;
}

.time_text4 {
  font-weight: 900;
  text-decoration: line-through;
  font-size: 16px;
}

.time_text5 {
  font-weight: 900;
  color: #FFFFFF;
  font-size: 16px;
}

.btn_time {
  background-color: #E34F12;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 25px;
  border: solid 1px #E34F12;

}

.btn_time:hover {
  background-color: #FFFFFF;
  color: #E34F12 !important;

}

/* ========= */

.inspire_space_btn {
  margin: auto;
}

.inspire_btn1 {
  background-color: #A8A9B4;
  border: solid 1px #000;
  color: #000;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inspire_btn1:hover {
  color: #44006b;

}

.inspire_btn2:hover {
  color: #A8A9B4;

}

.inspire_btn2 {
  background-color: #000;
  border: solid 1px #000;
  color: #FFFFFF;
  font-weight: bold;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}


.inspire_text {
  font-weight: bold;
  font-size: 14px;
}

.inspire_text_tache {
  text-decoration: line-through;
  font-weight: bold;
  font-size: 14px;
}

.inspire_text_small {
  opacity: .5;
  font-size: 14px;
}

.inspire_input {
  width: 70px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: solid 1px #d3cdcd;
  background-color: #fbfbfb;
  padding-top: 7px;
  padding-bottom: 7px;

}

.inspire_btn_order {
  background-color: #FE5011;
  border: solid 1px #FE5011;
  color: #FFFFFF;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;

}

.inspire_bar_sep {
  border-right: solid 3px #f2efef;
}

.save_text {
  font-weight: bold;
  font-style: italic;
  font-size: 16px;
}

.save_text1 {
  color: #5E17EB;
}

.bg_sec123 td {
  border: solid 2px #FFFFFF;
  background-color: #e7eaf0;
  padding: 10px;
}

.title_sec123 {
  font-weight: bold;
  text-align: center;
}

.text_sec123 {
  text-align: center;
}

.text_porc_sec123 {
  text-align: center;
  color: #5E17EB;
}

.bar_jett_sep001 {
  background-color: #E8EAF1;
  height: 20px;
}

.ship_sec_box {
  background-color: #E8EAF1;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 50px;
}

.ship_sec {
  margin-top: -50px !important;
}

.sec_bg001 {
  background-color: #000;
  padding: 30px;
  text-align: center;
  color: #FFFFFF;
}

.text1_sec_bg001 {
  color: #FFFFFF;
}

.img_inspire_book1 {
  width: 100%;
}

.text1_inspire_book1 {
  font-weight: bold;
  font-size: 22px;
  color: #FFFFFF;
}

.text2_inspire_book1 {
  font-size: 16px;
  color: #FFFFFF;
}

.inspire_btn_order2 {
  background-color: #000;
  border: solid 1px #000;
  color: #FFFFFF;
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;
}

.inspire_btn_order2:hover {
  background-color: #44006b;
}

.border8875 {
  border-right: solid 1px #E8A173;
}

.new_img1_part1_2 {
  width: 100%;
}

.btn_preorder_jett001 {
  background-color: #8029FE;
  border: solid 1px #8029FE;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: bold;
  color: #FFFFFF;
}

.btn_preorder_jett001:hover {
  background-color: transparent;

  color: #5E17EB;
}

.text_sec1234 {
  font-weight: bold;
  text-align: center;
  background-color: #CBC4F4;
}

.text_porc_sec1234 {
  text-align: center;
  color: #5E17EB;
}

.bg87574 {
  background-color: #CBC4F4 !important;
}

.more_jett001 {
  color: #5E17EB;
}

.border985758 {
  border-bottom: solid 3px #4f4f4f;

}

.a_header09 {
  text-decoration: underline !important;
  color: inherit;
}

.btn_inspire_buy {
  background-color: #E8EAF1;
  border: solid 1px #E8EAF1;
  color: #000;
  font-weight: bold;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 20px;
}

.shipping_img {
  width: 100%;
}

.theletter {
  background-color: #000;
  color: #FFFFFF;
}

.theletter_box {
  background-color: #E8EAF1;
  color: #000;
  padding: 25px;
}


.theletter_box1 {
  background-color: #E8EAF1;
  color: #000;
}


.theletter_book_img {
  width: 250px;
  margin-top: -285px;
}

.theletter_box_text {
  font-family: "Arimo", sans-serif;

  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000;
}

.text_aj1214 {
  font-family: "Oswald", Sans-serif;
  color: #000;
  opacity: .3;

}

.jett-font-italic {
  font-style: italic;
}

.jett_strong {
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  float: right;
  margin-top: 10px;
}

.ico_jett_close {
  width: 25px;
  margin-top: 10px;
  margin-left: -34px;
}

.btn_Video_serie1 {
  background-color: #FF5C23;
  border: solid 1px #FF5C23;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #FFFFFF;
  font-weight: bold;
}

.btn_Video_serie1:hover {
  background-color: transparent;
  color: #FF5C23 !important;
}

.btn_Video_serie2 {
  background-color: #5E17EB;
  border: solid 1px #5E17EB;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #FFFFFF;
  font-weight: bold;
}

.btn_Video_serie2:hover {
  background-color: transparent;
  color: #5E17EB !important;
}

.icon_star {
  width: 17px;
  margin-top: -8px;
}


.videoSerie_text1 {
  font-size: 45px;
  text-align: center;
  color: #FFFFFF;
  font-family: "Barriecito", system-ui;
  font-weight: 700;
  font-style: normal;
}

.videoSerie_text2 {
  font-size: 25px;
  font-weight: bold;
  color: #FB4E0E;
  text-align: center;
}

.videoSerie_text3 {
  text-align: center;
  color: #FFFFFF;
  opacity: .8;
}

.videoSerie_text4 {
  font-weight: bold;
}

.videoSerie_box1 {
  background-color: #DBDCF7;
  padding: 10px;
}

.bar_sep1001 {
  border-right: solid 2px #B2B2C2;
}

.bg_scroll_jett {
  background-color: #DBDCF7;
}

.cam_icon {
  width: 30px;
  margin-top: -4px;
}

.bar_mil {
  background-color: #FFFFFF;
  height: 1px;
  opacity: .2;
}

.engage_icon {
  font-size: 16px;
  margin-left: -3px;
}


.mil_text1 {
  font-size: 25px;
  text-align: center;
  color: #FFFFFF;
  font-family: "Barriecito", system-ui;
  font-weight: 700;
  font-style: normal;
}

.mil_text2 {
  font-size: 40px;
  line-height: 40px;
  color: #FB4E0E;
  font-weight: 700;
}

.mil_text3 {
  text-align: center;
  color: #FFFFFF;
  opacity: .8;
}

.mil_text4 {
  font-weight: bold;
}

.mil_box1 {
  background-color: #DBDCF7;
  padding: 10px;
}

.mil_text2-2 {
  font-weight: bold;
  color: #FFFFFF;
  font-size: 20px;
}

.main_picture_mill {
  width: 100%;
}

.videoSerie_text3-3 {
  font-style: italic;
}

.journey_text1 {
  font-weight: bold;
}

.journey_box2 {
  background-color: #FF4F0F;
  color: #FFFFFF;
  font-weight: 300;
}

.spread {
  background-color: #DBDCF7;
}

.spread_box {
  background-color: #000;
  font-weight: 900;
  font-size: 30px;
  color: #FFFFFF;
}

.btn_spread {
  background-color: #000;
  border: solid 1px #000;
  color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 20px;
  font-weight: 900;
}

.btn_spread:hover {
  background-color: transparent;
  color: #000;
}

.spread_text3 {
  font-weight: bold;
}

.link_jett {
  color: inherit;
}

.link_jett:hover {
  color: #8046FD;
}

.spread_img1 {
  width: 20%;
  float: left;
  margin-top: -180px;
}

.text_succes_up_video {
  color: #52BE80;
}

.btn_upload_video2 {
  background-color: #434164;
  border: solid 1px #434164;
  color: #FFFFFF;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 30px;
}
.btn_upload_video2:hover {
  background-color: transparent;
  color: #434164;
}
.btn_upload_video {
  background-color: #ff4e09;
  border: solid 1px #ff4e09;
  color: #FFFFFF;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 30px;
}

.btn_upload_video:hover {
  background-color: transparent;
  color: #ff4e09;
}

.reaction_box3_1 ul {
  list-style-type: none;
}


/* ======================= */
/* AudioPlayer.css */

/* AudioPlayer.css */

.play-pause-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: #FFFFFF;
  padding: 5px;
}

.play-pause-icon {
  width: 24px;
  /* Adjust size as needed */
  height: 24px;
  /* Adjust size as needed */
}

.volume-control {
  float: right;
  align-items: center;

}

.volume-control label {
  font-size: 14px;
  color: #333;
}


.volume-control input[type="range"] {
  -webkit-appearance: none;
  /* Remove default styling */
  width: 80px;
  /* Width of the volume bar */
  height: 4px;
  /* Height of the volume bar */
  background: #ddd;
  /* Background color of the track */
  border-radius: 4px;
  /* Rounded corners */
  outline: none;
  /* Remove default outline */
  cursor: pointer;
  /* Pointer cursor on hover */
  margin: 0;
  /* Remove default margin */
}

.volume-control input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Remove default thumb styling */
  width: 16px;
  /* Width of the thumb */
  height: 16px;
  /* Height of the thumb */
  background: #ff4e09;
  /* Color of the thumb */
  border-radius: 50%;
  /* Round thumb */
  cursor: pointer;
  /* Pointer cursor on hover */
}

.volume-control input[type="range"]::-moz-range-thumb {
  width: 16px;
  /* Width of the thumb */
  height: 16px;
  /* Height of the thumb */
  background: #ff4e09;
  /* Color of the thumb */
  border-radius: 50%;
  /* Round thumb */
  cursor: pointer;
  /* Pointer cursor on hover */
}

.volume-control input[type="range"]::-ms-thumb {
  width: 16px;
  /* Width of the thumb */
  height: 16px;
  /* Height of the thumb */
  background: #ff4e09;
  /* Color of the thumb */
  border-radius: 50%;
  /* Round thumb */
  cursor: pointer;
  /* Pointer cursor on hover */
}

.img_player {
  width: 30px;
  padding: 3px;
  background-color: #FFFFFF;
}

.img_revw {
  width: 30px;
}

.current-time {
  color: #FFFFFF;
}

.title_rev90 {
  border: solid 1px #6a39cc;
  background-color: #6a39cc;
  color: #FFFFFF;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 700;
  opacity: .6;
  color: #ff4e09;

}

.img1287 {
  width: 50px;
}

.text_new87659 {
  color: #000;
  font-weight: 200;
}

.video875 {
  width: 600px;
  height: 340px;

}

/* .space876{
  margin-top: -7px;
  margin-bottom: -30px;
} */
.text_disc {
  font-weight: 200;
  font-size: 14px;
  opacity: .9;
  margin-top: 10px;
}


.btn_buy_book20 {
  border: solid 1px #000;
  background-color: #000;
  color: #FFFFFF;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 700;
  font-family: "Oswald", Sans-serif;
  font-size: 20px;

}

.btn_buy_book20:hover {
  background-color: transparent;
  color: #000 !important;
}

/* ========= */

.buyDetails_title1 {
  font-size: 30px;
  font-family: "Oswald", Sans-serif;
  font-weight: 700;
  color: #000;
  text-align: center;
  color: #FFFFFF;
}


.buyDetails_btn1 {
  background-color: #eae7ff;
  border: solid 1px #eae7ff;
  color: #000;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 20px;
}

.buyDetails_btn2 {
  background-color: #eae7ff;
  border: solid 1px #eae7ff;
  color: #000;
  font-weight: bold;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 20px;
}



.selected {
  background-color: #ff4e09;
  /* Change this to your desired color */
  color: #FFFFFF;
  /* Optional: Change text color */
  border-color: #ff4e09;
  /* Optional: Change border color */
}

.kaptap_img {
  width: 50px;
}

.kaptab_text {
  font-family: "Macondo", cursive;
  font-weight: 400;
  font-style: normal;
  color: #000;
  font-size: 12px;
}

.buyDetails_box1 {
  background-color: #ff4e09;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 35px;

}

.buyDetails_box1_text1 {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
}

.buyDetails_box1_text2 {
  color: #FFFFFF;
  text-decoration: line-through;
  font-size: 20px;
}

.buyDetails_box1_text3 {
  color: #FFFFFF;
  font-size: 20px;
}

.buyDetails_box1_text4 {
  text-align: center;
  font-family: "Macondo", cursive;
  font-style: normal;
  color: #000;
  font-size: 22px;
  border-bottom: solid 2px #E8EAF1;
}

.buyDetails_table td {
  padding: 20px;
}

.buyDetails_btn_purchase {
  background-color: #eae7ff;
  border: solid 1px #eae7ff;
  color: #000;
  font-weight: 700;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
}

.buyDetails_btn_purchase:hover {
  background-color: transparent;
}

.buyDetails_text001 {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 25px;
}

.buyDetails_btn_amount {
  background-color: #E34F12;
  border: solid 1px #E34F12;
  color: #FFFFFF;
  font-weight: 700;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;

}

.bt_video875 {
  width: 460px;
  height: 300px;

}


.custom-modal-lg .modal-dialog {
  max-width: 90%;
  /* Adjust to your desired width */
}

.custom-modal-lg .modal-content {
  height: 80vh;
  /* Adjust to your desired height */
}

/* ========== */

#viewer {
  width: 100%;
  height: 500px;
  overflow: auto;
  /* Ensure scrolling is enabled */
}

.share_the_book {
  background-color: #eae7ff;
}

.border_left001 {
  border-right: solid 1px #cacfd2;
}

.btn_share_the_book {
  background-color: #000;
  border: solid 1px #a6acaf;
  color: #FFFFFF;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn_share_the_book:hover {
  background-color: transparent;
  color: #000;
}

.img_Book_Distributors_Logo {
  width: 150px;
}

.buy_pre_new001 {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  color: #FFFFFF;
  font-weight: 700;
  border-radius: 25px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 7px;
  padding-bottom: 9px;
}

.buy_pre_new001:hover {
  background-color: transparent;
  color: #FF4E09 !important;
}

.bullte_jett003 {
  list-style: square;
}

.li_gen009 {
  padding-bottom: 20px;
}

.img_buy_detail895 {
  width: 100%;
}

.btn_spread001 {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 25px;
}

.btn_spread001:hover {
  background-color: transparent;
  color: #FFFFFF !important;
}

.btn_spread002 {
  background-color: #FF4E09;
  border: solid 1px #FF4E09;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 25px;
}

.btn_spread002:hover {
  background-color: transparent;
  color: #FF4E09 !important;
}

.header_time_new {
  border-top: solid 1px #FFFFFF;
}

.text0_spread {
  font-size: 16px;
}

.text1_spread {
  font-size: 16px;
  color: #f70000;
  text-decoration: line-through;
}

.text2_spread {
  font-size: 16px;
  color: #00bf63;

}

.text_spread_box001 {
  font-weight: 700;
  line-height: 23px;
  color: #000;
}

.text_spread_box002 {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}

.text_spread_box003 {
  font-size: 14px;
}

.text_spread_box004 {
  color: #ff5011;
}

.text_spread_box005 {
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
}

.text_spread_box006 {
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.text_spread_box007 {
  font-size: 14px;
  line-height: 18px;
}

.text_spread_box008 {
  font-style: italic;
}
.text_spread_box009 {
  font-weight: 700;
  line-height: 32px;
  color: #000;
  font-size: 27px;
}
.text_spread_box010 {
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.img_spread002 {
  width: 220px;
}

.img_spread003 {
  width: 90px;
}

/* .img_spread003{
  width: 220px;
}*/
.img_spread004 {
  width: 140px;
}

.border_spread001 {
  border-right: solid 3px #e8e6e5;

}

.video_part_cont_new {
  background-color: #252325 !important;
}

.new_audio0398 {
  background-color: #252325;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 15px;
}

.btn_viewmore {
  border: none;
  background-color: inherit;
  font-weight: 700;
}

.gif_img {
  width: 155px;
}

.success_access_IA {
  background-color: #eef6ff;
}

.success_access_IA_border_down {
  border-bottom: solid 7px #434164;
  background-color: #000;
}

.text0_success_access_IA {
  background-color: #434164;
  color: #f3f3ff;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
}

.btn1_success_access_IA{
  background-color: #b2b2c2;
  border: solid 1px #b2b2c2;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 700;
}
.btn1_success_access_IA:hover{
  background-color: transparent;
  color: #000 !important;
}
.text1_success_access_IA{
  font-weight: 700;
  font-size: 22px;
  color: #000;
}
.box1_success_access_IA{
  background-color: #b2b2c2;
  border: solid 1px #000;
  font-weight: 700;
  font-size: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #000;
}
.box2_success_access_IA{
  background-color: #b2b2c2;
  border: solid 1px #000;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #000;
}
.text01_success_access_IA{
color: #000;
font-style: italic;
  
}

.text2_success_access_IA{
  line-height: 23px;
}

.btnAccess0085{
  color: #000 !important;
  font-weight: 700;
}
.text3_success_access_IA{
  font-size: 15px;
}
.btn2_success_access_IA{
  background-color: #ff5011;
  border: solid 1px #ff5011;
  color: #FFFFFF;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
}
.btn2_success_access_IA:hover{
  background-color: transparent;
  color: #ff5011 !important;
}
.boder_0974{
  border-right: solid 1px #2b2c2d;
}

.bg_vip{
  background-color: #ddddf4;
}
.checkbox_part2_jett2{
  font-size: 100px;
}


/* VIP.css */

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 1rem; /* Spacing above the checkbox */
}

.checkbox {
  display: none; /* Hide the default checkbox */
}

.custom-checkbox {
  width: 20px; /* Size of the custom checkbox */
  height: 20px; /* Size of the custom checkbox */
  border: 2px solid #ccc; /* Border style */
  border-radius: 4px; /* Rounded corners */
  position: relative; /* Position relative for the checkmark */
  cursor: pointer; /* Hand cursor on hover */
  margin-right: 10px; /* Space between checkbox and label */
  transition: border-color 0.3s; /* Transition effect for border color */
}

.checkbox:checked + .custom-checkbox {
  border-color: #434164; /* Border color when checked */
  background-color: #434164; /* Background color when checked */
}

.checkbox:checked + .custom-checkbox::after {
  content: ""; /* Create the checkmark */
  position: absolute;
  left: 5px; /* Position for checkmark */
  top: 2px; /* Position for checkmark */
  width: 5px; /* Width of checkmark */
  height: 10px; /* Height of checkmark */
  border: solid white; /* Checkmark color */
  border-width: 0 2px 2px 0; /* Shape of the checkmark */
  transform: rotate(45deg); /* Rotate the checkmark */
}

.checkbox-label {
  font-size: 14px; /* Font size of the label */
  color: #333; /* Label color */
  cursor: pointer; /* Hand cursor on hover */
}

.barTitle_success_access_IA{
  background-color: #000;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
  
}
.text1_barTitle_success_access_IA{
color: #11bd8e !important;
}
.bg97565{
  background-color: #ddddf4;
}
.text1_spread_box001{
  font-weight: 700;
  text-decoration: line-through;
  color: #000;
}
.text2_spread_box001{
  font-weight: 700;
  color: #167d61;
}
.btn_spread003{
  background-color: #fb4f0c;
  border: solid 1px #fb4f0c;
  color: #FFFFFF;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
}
.btn_spread003:hover{
  background-color: transparent;
  color: #fb4f0c !important;
  
}
.btn79403{
  background-color: #cfcfd7;
  border: solid 1px #cfcfd7;
  border-radius: 25px;
  padding: 5px 30px;
  font-weight: 700;
  color: #000;
  font-size: 14px;
}
.border857{
  border: solid 5px #7a7a7a;
}

.new_vip_text1{
  color: #ff3131;
  text-decoration: line-through;
}
.new_vip_text2{
  color: #11bd8e;
}