/* =========MOBILE================= */
@media screen and (min-device-width: 500px) and (max-device-width: 1000px) {
  .mobile_home_btn1 {
    display: block;
    text-align: center;
  }

  .home_new_text2 {
    border: none;
  }

  .jett_block {
    display: block !important;
  }

  .jett_block_double_slider {
    display: block !important;
    width: 100% !important;
  }

  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8 {
    display: block !important;
    width: 100% !important;
  }


  .jett_scrollmenu_in {
    display: inline-block !important;
  }

  .jett_new9848 {
    margin-top: 50px;
  }

  .slider li {
    width: auto!important;
  }
  .mask {
    height: 140px !important;
  }

  .header_time{
    margin: auto !important;
    text-align: center;
  }



  .mobile_space_header{
    margin-top: -15px !important;
  }

  .left_content {
    float: none;
  }
  .btn_home_jett_7864_3{
    padding-left:5px ;
    padding-right:5px ;
    font-size: 16px;
  }
  .border8759{
    border: none;
    padding: 5px !important;
  }
  .header_time{
    padding-top: 10px;
  }

  .shipping_img{
    width: 25%;
  }
  .border8875{
    border-right: none;
  }
  .text1_inspire_book1{
    font-size: 20px;
  }

  .mobile_text98635{
    margin-left: 46px;
  }

  .video875{
    width: 100%;
    margin-top: -45px;
  }
  .text2_part22{
   margin-top: -30px;
   margin-bottom: -32px;
  }
  
  .content-slider{
   margin-top: -50px;
   margin-bottom: -20px;
  }

.buyDetails_title1{
  font-size: 25px;
}
.btn_home_jett_7864{
  font-size: 16px;
}
  .bt_video875{
    width: 100%;
  }

.image_slider{
  width: 200px;
}


.space876{
  margin-top: -100px !important;
}

.btn_buy_book:hover {
  background-color: #000;
  color: #FFFFFF;
}

.img_buy_detail895{
  width: 50%;
 }

 .f9086765{
  margin-top: 30px;
 }
 .space87540{
  margin-top: -70px !important;
 }
 .space875478{
  margin-top: -50px !important;
 }
 .buy_new_1{
  font-size: 27px !important;
  line-height: 34px;
 }

 .gif_img{
  width: 140px;
}

.time_text1{
  font-size: 22px;
}
.text_spread_box005{
  font-size: 22px;
}
.text3_speaking_slider{
  font-size: 16px;
}
.buy_por984774_text1{
  font-size: 40px;
}
.text2_success_access_IA{
  line-height: 25px;
}
.barTitle_success_access_IA{
  line-height: 40px;
}

.btn_share_the_book{
  font-size: 14px;
}

}


/* ================================= */
/* @media (max-width: 1297px) {
  .menu-section {
      display: none;
  }
} */

/* ============================================== */
@media only screen and (max-width: 1001px) {
  .btn_share_the_book{
    font-size: 14px;
  }
  .barTitle_success_access_IA{
    line-height: 40px;
  }

  .text2_success_access_IA{
    line-height: 25px;
  }
  .buy_por984774_text1{
    font-size: 40px;
  }
  .text3_speaking_slider{
    font-size: 16px;
  }
  .text_spread_box005{
    font-size: 22px;
  }
  .time_text1{
    font-size: 22px;
  }
  .gif_img{
    width: 140px;
  }
  

  .buy_new_1{
    font-size: 27px !important;
    line-height: 34px;
   }
  .space875478{
    margin-top: -50px !important;
   }
  .space87540{
    margin-top: -70px !important;
   }
  
  .f9086765{
    margin-top: 30px;
   }

  .img_buy_detail895{
    width: 50%;
   }
  
  .btn_buy_book:hover {
    background-color: #000;
    color: #FFFFFF;
  }
  

  .space876{
    margin-top: -90px !important;
  }
  
  .image_slider{
    width: 200px;
  }

  .bt_video875{
    width: 100%;
  }
  .btn_home_jett_7864{
    font-size: 16px;
  }
    
  .buyDetails_title1{
    font-size: 25px;
  }
  .content-slider{
   margin-top: -50px;
   margin-bottom: -20px;
  }
  .text2_part22{
   margin-top: -30px;
   margin-bottom: -32px;
  }
  .video875{
    width: 100%;
    margin-top: -45px;
  }
  .mobile_text98635{
    margin-left: 46px;
  }

  .text1_inspire_book1{
    font-size: 20px;
  }
  .border8875{
    border-right: none;
  }
  .shipping_img{
    width: 25%;
  }
  .header_time{
    padding-top: 10px;
  }
  .border8759{
    border: none;
    padding: 5px !important;
  }
  .btn_home_jett_7864_3{
    padding-left:5px ;
    padding-right:5px ;
    font-size: 16px;
  }
  .btn_home_jett_7864_4{
    font-size: 16px;
  }
  .mobile_space_header{
    margin-top: -15px !important;
  }
  
  .new_about_bar2{
    font-size: 21px !important;
  }

  .header_time{
    margin: auto !important;
    text-align: center;
  }

  .part6 {
    background-image:none;
    background-color: #000;
    height: 500px;
  }
  .slider .source {
    margin-left: 100px;
  }
  .mobile_break{
    display: block;
    margin-left: 45px;
  }

  .part6_header{
margin-top: 2px !important;
  }
  
  .slider li {
    width: 320px !important;
  }
  .mask {
    height: 140px !important;
  }
  .mobile_home_btn1 {
    display: block;
    text-align: center;

  }
  .reaction_header_1{
    font-size: 30px !important;
    line-height: 40px;
  }

  .text_header_jett_89 {
    font-size: 30px;
  }

  .text2_header_jett_89 {
    color: #000 !important;
    font-family: 'Barrio', system-ui !important;
    font-size: 36px !important;
    line-height: 55px;
  }

  .part3_video_box {
    text-align: center;
  }

  .text1_part4 {
    line-height: 55px;
    font-size: 34px;
  }

  .text1_part6 {
    line-height: 55px;
  }

  .book_down2 {
    line-height: 55px;
    font-size: 20px !important;
  }

  .btn_jett_slider_book {
    display: block;

    margin-top: 10px;
    margin-left: 0px;

  }


  .border_img1_part1_2 {
    border: none;
  }

  .pkl_908 {
    margin-top: 0;
  }

  .contact_part2_box1 {
    border: none;
  }

  .contact_part3_btn1 {
    margin-top: 10px;
  }

  .home_new_text2 {
    border: none;
  }

  .comm_text1 {
    border: none;
  }

  .separation2345 {
    border: none;
  }

  .jett84646 {
    display: block;
    margin-top: 60px !important;

  }

  .eapce95875 {
    padding-left: 0px !important;
  }

  .img_buy_footer123 {
    width: 100%;
  }

  .left_content {
    float: none;
  }

  .space10 {
    margin-top: -20px;
  }

  .text_slider2 {
    font-size: 30px;
  }

  .text_slider5 {
    line-height: 33px;
    font-weight: 400;
  }

  .text6_part6 {
    font-size: 20px;
  }

  .part_jett_home_new10 {
    font-size: 34px;
    line-height: 45px;
  }

  .book_down2 {
    font-size: 34px;
    line-height: 45px;
  }

  .book_down_text2 {
    width: 300px;
    padding: 10px;
    margin-bottom: -10px;
  }
  .book_down_text20 {
    width: 300px;
    padding: 10px;

  }

  .text4_footer {
    font-size: 14px;
  }

  .text3_footer {
    text-align: center;
  }

  .footer_jett_flot54 {
    float: none;
  }

  .text_book_part3 {
    line-height: 50px;
    margin-bottom: 10px;
  }

  .book_down_text1 {
    font-size: 20px;
  }

  .slider_book_text4 {
    line-height: 45px;
  }

  .text2_speaking_slider {
    font-size: 19px;
    line-height: 30px;
  }

  .text1_social_about {
    font-size: 34px;
    line-height: 45px;
  }

  .jett_text_7464 {
    font-size: 34px !important;
  }

  .black_bar_book_down {
    margin-top: 0px;
  }

  .text2_part3003 {
    line-height: 40px;
  }

  .slider_book_text1 {
    font-size: 34px;
  }

  .contact_text10 {
    line-height: 45px;
  }

  .podcast_part3_box1_other {
    font-size: 22px;
    line-height: 32px;
  }

  .podcast_part3_box1 {
    font-size: 22px;
    line-height: 32px;
  }

  .buy_7585 {
    margin-top: 10px;
  }

  .btn_buy_now {
    border-radius: 1px;
  }

  .btn_buy_now2 {
    border-radius: 1px;
  }

  .bg_84763543 {
    height: 50px;
  }

  .way_to_contribute_box1 {
    font-size: 35px;
    line-height: 45px;
  }

  .way_to_contribute_box2 {
    font-size: 35px;
    line-height: 45px;
  }

  .way_to_contribute_box3 {
    font-size: 35px;
    line-height: 45px;
  }

  .way_to_contribute_box4 {
    font-size: 35px;
    line-height: 45px;
  }

  .way_to_contribute_box5 {
    font-size: 35px;
    line-height: 45px;
  }

  .text1_slider_donate_new {
    font-size: 34px;
  }

  .image_donate_bar2 {
    width: 100px;
  }

  .donate_bar2 {
    background-image: linear-gradient(to right, #FF4E09, #FF4E09, #FF4E09, #FF4E09, #FF4E09, #FF4E09);

  }

  .cont763658 {
    font-size: 30px;
    line-height: 45px;
  }

  .donate_bottom_text {
    font-size: 22px;
  }

  .podcast_form_content {
    padding: 15px;
  }

  .home_new_text4 {
    border: none;
  }

  .reaction_bg_img {
    height: 200px;
  }
}






/* Your landscape-specific styles here */
/* @media (orientation: landscape) {
  .text2_part22{
    margin-bottom: 40px !important;
  }
  .space876{
    margin-top: 5px !important;
  }
  .space877{
    margin-bottom: 50px !important;
  }
} */
 


@media only screen and (max-width: 1100px) and (orientation: landscape) {
  .text2_part22{
    margin-bottom: 40px !important;
  }
  .space876{
    margin-top: 5px !important;
  }
  .space877{
    margin-bottom: 50px !important;
  }
}




